var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.contractShowcaseEnabled)?_c('div',{staticClass:"molecule-contract-details-container"},[(_vm.spaceData.available)?_c('div',{class:{
      inner: true,
      available: true,
      negotiating: _vm.spaceData.contract_under_negotiation
    }},[(_vm.spaceData.contract_under_negotiation)?_c('p',{staticClass:"text-contract"},[_vm._v("\n      "+_vm._s(_vm.$t('under_negotiation'))+"\n    ")]):_c('p',{staticClass:"text-contract"},[_vm._v(_vm._s(_vm.$t('not_under_negotiation')))])]):_vm._e(),_vm._v(" "),(
      !_vm.spaceData.available &&
      (_vm.spaceData.contract_status || _vm.spaceData.contract_number || _vm.spaceData.contract_client)
    )?_c('div',{staticClass:"inner unavailable"},[_c('h4',[_vm._v(_vm._s(_vm.$t('contract_title')))]),_vm._v(" "),_c('div',{staticClass:"contract-info"},[(_vm.spaceData.contract_status)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('contract_status'))+":")]),_vm._v(" "+_vm._s(_vm.$t(_vm.spaceData.contract_status))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.spaceData.contract_number)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('contract_number'))+":")]),_vm._v(" "+_vm._s(_vm.spaceData.contract_number)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.spaceData.contract_client)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('contract_client'))+":")]),_vm._v(" "+_vm._s(_vm.spaceData.contract_client)+"\n      ")]):_vm._e()])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }