var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"molecule-popup-header",attrs:{"id":"molecule-sidebar-header"}},[_c('div',{staticClass:"title-and-info"},[_c('div',{staticClass:"filters-title-and-button"},[_c('div',{staticClass:"filters-title"},[_c('p',[_c('AtomsCommonAtomIcon',{attrs:{"icon":"filter-solid","size":26}}),_vm._v("\n          "+_vm._s(_vm.$t('filters'))+"\n          "),(_vm.activeFilterslen > 0)?_c('span',{staticClass:"number"},[_vm._v("/ "+_vm._s(_vm.activeFilterslen))]):_vm._e(),_vm._v(" "),(_vm.activeFilterslen > 0)?_c('span',{staticClass:"clear-all",on:{"click":_vm.handleClearFilters}},[_vm._v("\n            "+_vm._s(_vm.$t('clearAll'))+"\n          ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"filters-trigger",on:{"click":_vm.toggleShowFilters}},[(!_vm.showFilters)?_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('showFilters'))+"\n          "),_c('AtomsCommonAtomIcon',{attrs:{"icon":"sort-down","size":26}})],1):_c('p',{staticClass:"inverse-arrow"},[_vm._v("\n          "+_vm._s(_vm.$t('hideFilters'))+"\n          "),_c('AtomsCommonAtomIcon',{attrs:{"icon":"sort-down","size":26}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"filters clearfix",class:{ visible: _vm.showFilters }},[_c('div',{staticClass:"row filters-row",attrs:{"id":"filter-spaces","action":""}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[(Object.keys(_vm.locationOptions).length > 2)?_c('div',{staticClass:"col col-padding-4px"},[_c('div',{staticClass:"select-style"},[_c('AtomsCommonAtomSelect',{attrs:{"id":"location","options":_vm.locationOptions,"has-label":"","label-text":_vm.$t('location'),"select-props":{
                  search: false,
                  placeholder: _vm.$t('location')
                },"value":_vm.locationOptions.find((o) => o.id === _vm.filters.location),"option-label":"text"},on:{"change":($event) => _vm.handleFilterChange($event, 'location', $event.id)}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col col-padding-4px"},[_c('div',{staticClass:"select-style"},[_c('AtomsCommonAtomSelect',{attrs:{"id":"spaceType","options":_vm.spaceTypeOptions,"has-label":"","label-text":_vm.$t('spaceType'),"select-props":{
                  search: false,
                  placeholder: _vm.$t('spaceType')
                },"option-label":"text","value":_vm.spaceTypeSelectValue || _vm.spaceTypeOptions[0]},on:{"change":($event) => _vm.handleFilterChange($event, 'spaceType', $event.id)}})],1)])]),_vm._v(" "),_c('div',{staticClass:"row m-b-8"},[_c('div',{staticClass:"col col-padding-4px"},[_c('div',{staticClass:"select-style"},[_c('AtomsCommonAtomDropdownFilter',{attrs:{"placeholder":_vm.$t('floorInfo'),"options":_vm.floorOptions,"value":_vm.filters.floors},on:{"input":($event) => _vm.handleFilterChange($event, 'floors')}})],1)]),_vm._v(" "),_c('div',{staticClass:"col col-padding-4px"},[_c('div',{staticClass:"select-style"},[_c('AtomsCommonAtomSelect',{attrs:{"id":"orientation","options":_vm.orientationOptions,"has-label":"","label-text":_vm.$t('orientation'),"select-props":{
                  search: false,
                  placeholder: _vm.$t('orientation')
                },"value":_vm.orientationOptions.find((o) => o.id === _vm.filters.orientation),"option-label":"text"},on:{"change":($event) => _vm.handleFilterChange($event, 'orientation', $event.id)}})],1)])]),_vm._v(" "),_c('div',{staticClass:"row border-1px-bottom"},[_c('div',{staticClass:"col col-padding-13px"},[_c('p',{staticClass:"title-filter"},[_vm._v(_vm._s(_vm.$t('surface'))+" ("+_vm._s(_vm.$t(_vm.unitOfMeasureString))+")")]),_vm._v(" "),_c('div',{},[_c('AtomsCommonRangeAtomDoubleRangeSlider',{attrs:{"min":_vm.surfaceRange[0],"max":_vm.surfaceRange[1],"tooltip-formatter-rule":"surfaceRule"},model:{value:(_vm.sliderSurfaceValues),callback:function ($$v) {_vm.sliderSurfaceValues=$$v},expression:"sliderSurfaceValues"}})],1)]),_vm._v(" "),_c('div',{staticClass:"col col-padding-13px"},[_c('p',{staticClass:"title-filter"},[_vm._v(_vm._s(_vm.$t('price'))+" ("+_vm._s(_vm.currency)+")")]),_vm._v(" "),_c('div',{},[_c('AtomsCommonRangeAtomDoubleRangeSlider',{attrs:{"min":_vm.priceRange[0],"max":_vm.priceRange[1],"tooltip-formatter-rule":"priceRule"},model:{value:(_vm.sliderPriceValues),callback:function ($$v) {_vm.sliderPriceValues=$$v},expression:"sliderPriceValues"}})],1)]),_vm._v(" "),_c('div',{staticClass:"col col-12"},[_c('AtomsCommonAtomButton',{staticClass:"hidden",attrs:{"label":_vm.$t('filter')}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-padding-4px"},[_c('MoleculesCommonAvailabilityMoleculeMultipleOptions',{ref:"multipleOpts",attrs:{"options":_vm.computedAmenities,"title":_vm.$t('amenities'),"value":_vm.filters.amenities},on:{"input":($event) => _vm.handleFilterChange($event, 'amenities')}})],1)])])])]),_vm._v(" "),(_vm.activeFilterslen > 0)?_c('div',{staticClass:"results-counter"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.totalAvailableSpacesText)}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }